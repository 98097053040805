
let StateApp =  {
    auth: 
        {
            login: null,
            email: null,
            token: null 
        }
}


export default StateApp;


