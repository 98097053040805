import Styles from './DefaultLayout.module.scss';

import { NavLink } from 'react-router-dom';


import MainNav from '../../MainNav/MainNav';
import SiteInfo from '../../SiteInfo/SiteInfo';

import Filter from '../../UI/Filter/Filter'

function DefaultLayout(props) {
    
   
    let chengeStatusFilter = props.chengeStatusFilter;
    let onFilterResult = props.onFilterResult;
 

    let filter = {
                minPriceProduct: props.filterParams.minPriceProduct,
                maxPriceProduct: props.filterParams.maxPriceProduct,
                secelctMinPriceProduct: props.filterParams.secelctMinPriceProduct,
                secelctMaxPriceProduct: props.filterParams.secelctMaxPriceProduct,
            }
    
    return (
        <div className={Styles.DefaultLayout}>
            <header>
                <div class = {Styles.HeaderFon}>
                    
                </div>
                <div className='header_content'>
                    <div className='wrap'>
                        <div className='logo_info'>
                            <img src="/logo.png" className='logo' />
                            <SiteInfo />
                        </div>
                        <MainNav />
                        
                        <div className='Login'>
                            <NavLink
                                to="/login"

                                className='loginBtn'

                            >
                                Login1
                            </NavLink>
                        </div>
                    </div>

                    
                </div>
                
            </header>
            
            <section>

                <aside>
                    {
                        (props.filterShow)?
                            <Filter filterParams={filter} 
                            chengeStatusFilter={chengeStatusFilter}
                            onFilterResult={onFilterResult}/>
                           
                        :
                            ''
                    }
                </aside>
                
                {props.children}
            </section>

            {/* <footer>
                подвал
            </footer> */}
        </div>
    );

}

export default DefaultLayout;


   
  
