import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import Requests from "../../../Requests";
// import './Style.css';

import classNames from 'classnames';
import AddCategory from "./AddCategory";
import Loader from "../../../Loader/Loader";
import Graph from "../Graph/Graph";

import UsersMap from "./UsersMap";


function Dashboard(props){
    const navigate = useNavigate();
    useEffect(() => {

        
        
        let token = localStorage.getItem('token');

        if(!token){
            navigate('/login');
        }

        //Requests()

    });

    return (

            <div>Dashboard
          
                <Graph/>
                <UsersMap/>

                <AddCategory/>

                
                
            </div>
    );
}

export default Dashboard;