import Styles from './SiteInfo.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function SiteInfo() {
  return (
    <div className={Styles.SiteInfo}>
      <div>
            <i></i>

            <div>
                <span>Our Location</span>
                <p>25/7 Barden, London</p>
            </div>
      </div>
      <div>
            <i></i>

            <div>
                <span>Our Location</span>
                <p>25/7 Barden, London</p>
            </div>
      </div>
      <div>
            <i></i>

            <div>
                <span>Our Location</span>
                <p>25/7 Barden, London</p>
            </div>
      </div>
    </div>
  );
}

export default SiteInfo;
